import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
} from '@angular/forms';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgxMaskDirective } from 'ngx-mask';

import { SmSelectComponent } from '../sm-select/sm-select.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { searchAccountContractValidator } from '@core/modules/scheduler/data/helpers';

@Component({
  selector: 'scheduler-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SvgIconComponent,
    SmSelectComponent,
    NgxMaskDirective,
  ],
  templateUrl: './scheduler-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerFormComponent {
  readonly $isLoading = signal(false);
  private readonly _formBuilder = inject(FormBuilder);

  @Input() set isLoading(isLoading: boolean) {
    this.$isLoading.set(isLoading);
  }

  readonly formGroup = this._formBuilder.group(
    {
      account_contract: [
        '',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(/^[0-9,$]*$/),
        ],
      ],
      scheduling_name: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)],
      ],
      scheduling_phone: [
        '',
        [Validators.required, Validators.pattern(/^[0-9,$]*$/)],
      ],
      technical_referral: ['', [Validators.pattern(/^[0-9,$]*$/)]],
      data_policy_accepted: [true, [Validators.requiredTrue]],
      process_accepted: [true, [Validators.requiredTrue]],
      will_attend: 'YES',
      attendant_name: '',
      attendant_phone: '',
      visitor_data_policy_accepted: false,
    },
    {
      validators: searchAccountContractValidator(),
    },
  );

  readonly $showExtraData = toSignal(
    this.formGroup.valueChanges.pipe(
      map((value) => {
        return value.will_attend === 'NO';
      }),
    ),
  );

  @Output() onSubmit = new EventEmitter<FormGroup>();

  submitForm() {
    this.onSubmit.emit(this.formGroup);
  }
}
