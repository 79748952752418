import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, interval, Subscription, takeWhile } from 'rxjs';

import { OptInputComponent } from '../opt-input/opt-input.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MaskedTextPipe } from '@core/shared/pipes';
import { TActionOPT } from '@core/shared/types';
@Component({
  selector: 'otp-modal-content',
  standalone: true,
  imports: [NgClass, OptInputComponent, SvgIconComponent, MaskedTextPipe],
  templateUrl: './otp-modal-content.component.html',
})
export class OtpModalContentComponent {
  @Output() onConfirm = new EventEmitter<TActionOPT>();
  @Output() onResetCode = new EventEmitter<void>();
  @Output() countdownFinished = new EventEmitter<void>();
  @Input() startSeconds: number = 60;

  subscription: Subscription = new Subscription();
  public remainingTime$ = new BehaviorSubject<number>(this.startSeconds);

  readonly $phone = signal('');

  @Input() set phone(phone: string) {
    this.$phone.set(phone);
  }

  ngOnInit() {
    this.startTimer();
  }

  startTimer(): void {
    this.subscription.unsubscribe();
    this.remainingTime$.next(this.startSeconds);

    this.subscription = interval(1000)
      .pipe(takeWhile(() => this.remainingTime$.value > 0))
      .subscribe(() => {
        const newTime = this.remainingTime$.value - 1;
        this.remainingTime$.next(newTime);

        if (newTime === 0) {
          this.countdownFinished.emit();
        }
      });
  }

  readonly $timer = toSignal(this.remainingTime$);

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onReset() {
    this.startTimer();
    this.onResetCode.emit();
  }

  otpCode(code: string) {
    if (code) {
      this.onConfirm.emit({ action: 'CONFIRMED_OTP', payload: code });
    }
  }
}
