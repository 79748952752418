<div
  class="relative transform rounded-xl text-center transition-all sm:w-full sm:max-w-xl bg-neutral-40"
>
  <div class="xs:p-2 lg:px-4 lg:py-8">
    <div class="xs:flex xs:flex-col lg:grid lg:items-center">
      <div class="text-center">
        <h2 class="font-bold text-accent" id="modal-title">
          Ingresa el código enviado
        </h2>
        <div class="mt-5">
          <p class="text-black">
            Se ha enviado el
            <span class="font-bold">
              código OTP al celular {{ $phone() | maskedText:3:2 }}
            </span>
            registrado del cliente, por favor verificalo e ingrésalo a continuación.
          </p>
        </div>

        <opt-input [length]="6" (onValueChange)="otpCode($event)" />

        <div class="mt-5 flex justify-center gap-4">
          @if ($timer() === 0) {
            <p class="text-black xs:w-52 lg:w-full">
              ¿Aún no recibes el código?
              <span
                class="text-gray-500 ms-2 font-bold cursor-pointer hover:text-gray-700"
                (click)="onReset()"
              >
                Reenviar código
              </span>
            </p>
          }
        </div>

        <div class="mt-5">
          <p class="text-black">
            Tienes <span class="font-bold">{{ $timer() }} segundos</span> para ingresar el código
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
