<div>
  <mat-card class="datepicker-card">
    <mat-calendar
      [(selected)]="userSelectDate"
      (selectedChange)="onDateSelected($event)"
      [dateClass]="dateClass"
      [minDate]="today"
      [dateFilter]="dateFilter"
    />
  </mat-card>
</div>
