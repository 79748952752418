import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { IResponseMap } from '@core/domain/interfaces';
import { SearchAccountResponseEntity } from '@core/domain/entities/scheduler/entities';
import { TAccountContract } from '@core/domain/entities/scheduler/types';
import { SchedulerService } from '@core/shared/services/scheduler';
import { TFacadeResponse } from '@core/shared/types/general.type';
import { TConfirmOTP, TGenerateOTPCode } from '@core/shared/types';

@Injectable({
  providedIn: 'root',
})
export class SchedulerFacade {
  private readonly schedulerService = inject(SchedulerService);

  getAccountContractSearch(
    contract: TAccountContract,
  ): Observable<IResponseMap<SearchAccountResponseEntity>> {
    return this.schedulerService.getAccountContractSchedules(contract).pipe(
      map((resp) => {
        const data = SearchAccountResponseEntity.fromJson(
          resp.validateAccountContractData,
        );

        return {
          success: true,
          data,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message,
          data: null,
        });
      }),
    );
  }

  generateOTPCodeByPhone(
    phone: string,
  ): Observable<TFacadeResponse<TGenerateOTPCode>> {
    return this.schedulerService.generateOTPCode(phone).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message,
          data: null,
        });
      }),
    );
  }

  validateOTPCode(
    otp: string,
    phone: string,
  ): Observable<TFacadeResponse<TConfirmOTP>> {
    return this.schedulerService.confirmOTPCode(otp, phone).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message as string,
          data: null,
        });
      }),
    );
  }
}
