import { Component, inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

import { OtpModalContentComponent } from '../otp-modal-content/otp-modal-content.component';
import { LoaderContentComponent } from '../loader-content/loader-content.component';
import { TActionOPT, TParamsCreateAppointment } from '@core/shared/types';
import { SchedulerFacade } from '@core/modules/scheduler/data/facades';
import { NotifyService } from '@core/shared/services';
import { MONTHS } from '@core/shared/constants';

type TParamsData = { data: TParamsCreateAppointment };

@Component({
  selector: 'confirm-create-appointment',
  standalone: true,
  imports: [OtpModalContentComponent, LoaderContentComponent],
  templateUrl: './confirm-create-appointment.component.html',
})
export class ConfirmCreateAppointmentComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly notify = inject(NotifyService);
  private readonly dialogRef = inject(
    MatDialogRef<ConfirmCreateAppointmentComponent>,
  );
  private readonly schedulerFacade = inject(SchedulerFacade);
  readonly paramsData = inject<TParamsData>(MAT_DIALOG_DATA);
  readonly otp = signal('');
  readonly isFetching = signal(false);

  ngOnInit() {
    this.makeRequestOTP();
  }

  get appointment() {
    const data = this.paramsData.data.availableCapability;
    return data?.time_slots_info?.label;
  }

  get dateformat() {
    const date = this.paramsData.data?.availableCapability
      ?.date as unknown as DateTime;
    return `${date.day} de ${MONTHS.at(date.month)} de ${date.year}`;
  }

  get phone() {
    return this.paramsData.data?.phone;
  }

  onClose() {
    this.dialogRef.close();
  }

  onCreate() {
    this.onClose();
    this.router.navigate(['/scheduler/view']);
  }

  makeRequestOTP() {
    this.isFetching.set(true);
    this.schedulerFacade.generateOTPCodeByPhone(this.phone).subscribe({
      next: (resp) => {
        const errMessage = resp?.message || resp?.data?.message;

        if (resp.success === false) {
          this.notify.error('Espera!', errMessage);
          return;
        }

        if (resp.success && resp.data.status === 'ERROR') {
          this.notify.error('Espera!', errMessage);
          return;
        }
      },
      complete: () => this.isFetching.set(false),
    });
  }

  otpAction(event: TActionOPT) {
    switch (event.action) {
      case 'CLOSE_MODAL':
        this.onClose();
        return;

      case 'CONFIRMED_OTP':
        this.otp.set(event.payload);
        return;
    }
  }

  onResetOTPCode() {
    this.makeRequestOTP();
  }

  confirmOTP() {
    this.schedulerFacade.validateOTPCode(this.otp(), this.phone).subscribe({
      next: (resp) => {
        if (resp.success === false) {
          this.notify.error('Espera!', resp.message);
          return;
        }

        if (!resp?.data?.data) {
          this.notify.warn('Espera', 'No es un código válido');
          return;
        }

        this.notify.done('Hecho', 'Validación correcta');
        this.onClose();
      },
    });
  }
}
